import React from "react";
import TabButton from "./TabButton";
// import { FormContext } from '../../Store/form-context';

const TabWrapper = () => {
  // const { state, dispatch } = useContext(FormContext);

  return (
    <div className="search-container">
      <div className="tabs">
        <ul className="no-styling form-tabs js-tabs">
          <TabButton tabType="domain" value="dns" />
          <TabButton tabType="domain" value="ip" />
          <TabButton tabType="domain" value="whois" />
          <TabButton tabType="domain" value="info" />
        </ul>
      </div>
    </div>
  );
};

export default TabWrapper;
