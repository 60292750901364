import React from "react";
import "./HeroWrapper.css";

import Illustration from "../../assets/illustration.svg";

const HeroWrapper = (props) => {
  return (
    <div className="hero">
      <div className="illustration">
        <img src={Illustration} alt="Illustration" />
      </div>
      <div className="hero-content">
        <div className="logo">{props.text}</div>
        <div></div>
      </div>
    </div>
  );
};

export default HeroWrapper;
