import React, { createContext, useReducer } from "react";
import { isUrlValid, isIpv4Valid, isIpv6Valid } from "../utility/validation";

const initState = {
  dns: {
    inputValue: "",
    isValid: false,
    isEmpty: true,
    dnsType: "A",
  },
  ip: {
    inputValue: "",
    isValid: false,
    isEmpty: true,
  },
  whois: {
    inputValue: "",
    isValid: false,
    isEmpty: true,
  },
  info: {
    inputValue: "In here you can write your own info with Markdown",
  },
  tabType: "dns",
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "ADD_DNS":
      let dnsExtra;
      let isDNSValidated;
      action.payload.inputValue.indexOf("http://") >= 0 || action.payload.inputValue.indexOf("https://") >= 0
        ? (dnsExtra = "")
        : (dnsExtra = "https://");
      isDNSValidated = isUrlValid(dnsExtra + action.payload.inputValue.trim());
      return {
        ...state,
        dns: {
          ...state.dns,
          inputValue: action.payload.inputValue,
          isValid: isDNSValidated,
          isEmpty: action.payload.inputValue.length > 0 ? false : true,
        },
      };
    case "ADD_IP":
      let isIPValidated = false;
      if (isIpv4Valid(action.payload.inputValue.trim())) isIPValidated = true;
      if (isIpv6Valid(action.payload.inputValue.trim())) isIPValidated = true;
      return {
        ...state,
        ip: {
          ...state.ip,
          inputValue: action.payload.inputValue,
          isValid: isIPValidated,
          isEmpty: action.payload.inputValue.length > 0 ? false : true,
        },
      };
    case "ADD_WHOIS":
      let whoisExtra;
      action.payload.inputValue.indexOf("http://") >= 0 || action.payload.inputValue.indexOf("https://") >= 0
        ? (whoisExtra = "")
        : (whoisExtra = "https://");
      let isWhoisValidated = false;
      if (isUrlValid(whoisExtra + action.payload.inputValue.trim())) isWhoisValidated = true;
      if (isIpv4Valid(action.payload.inputValue.trim())) isWhoisValidated = true;
      if (isIpv6Valid(action.payload.inputValue.trim())) isWhoisValidated = true;
      return {
        ...state,
        whois: {
          ...state.whois,
          inputValue: action.payload.inputValue,
          isValid: isWhoisValidated,
          isEmpty: action.payload.inputValue.length > 0 ? false : true,
        },
      };
    case "ADD_INFO":
      return {
        ...state,
        info: {
          ...state.info,
          inputValue: action.payload.inputValue,
        },
      };
    case "CHANGE_TAB_TYPE":
      return {
        ...state,
        tabType: action.payload.tabType,
      };
    case "CHANGE_DNS_TYPE":
      return {
        ...state,
        dns: {
          ...state.dns,
          dnsType: action.payload.dnsType,
        },
      };
    default:
      return state;
  }
};

export const FormContext = createContext(initState);

const FormProvider = (props) => {
  const [state, dispatch] = useReducer(formReducer, initState);

  return <FormContext.Provider value={{ state, dispatch }}>{props.children}</FormContext.Provider>;
};

export default FormProvider;
