import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import "./ResultBox.css";

const trollDomains = ["domaintools.nu"];

const ResultBox = ({ tabType, data, domainType, error, domain }) => {
  const renderDomainData = useCallback((domainData, domainType) => {
    // if domainData is an array - loop through it
    if (Array.isArray(domainData)) {
      // console.log('[Resultbox.js] - Array.isArray')
      const listItems = domainData.map((arrayItem, arrayIndex) => {
        // see if contains object in head array and not another array
        if (typeof arrayItem === "object" && !Array.isArray(arrayItem)) {
          // console.log('[Resultbox.js] - Array.isArray -> typeof === object');
          return Object.keys(arrayItem).map((objectKey, objectIndex) => {
            return (
              <li key={objectIndex}>
                {`${objectKey}: `}
                <span className="text-light">{arrayItem[objectKey]}</span>
              </li>
            );
          });
        }

        // else return array items as <li>-elements
        return (
          <li key={arrayIndex}>
            {domainType}:{" "}
            <span className="text-light">
              {arrayItem}{" "}
              {domainType === "A" || domainType === "AAAA" ? (
                <Link style={{ marginLeft: ".5rem" }} to={`/ip?ip=${arrayItem}`}>
                  <FontAwesomeIcon icon={faLink} />
                </Link>
              ) : null}
            </span>
          </li>
        );
      });
      return listItems;
    }

    // if domainData is an object - loop trough it
    // and return every property as <li>-element
    if (typeof domainData === "object" && !Array.isArray(domainData)) {
      // console.log('[Resultbox.js] typeof === object')
      return Object.keys(domainData).map((objectKey, objectIndex) => {
        return (
          <li key={objectIndex}>
            {`${objectKey}:`}
            <span className="text-light">{domainData[objectKey]}</span>
          </li>
        );
      });
    }

    if (typeof domainData === "string") {
      return <li>{domainData}</li>;
    }
  }, []);

  const renderWhoisData = useCallback((whoisData) => {
    return <pre>{whoisData}</pre>;
  }, []);

  const renderIpData = useCallback((IpData) => {
    // console.log(IpData)
    return (
      <>
        <li>
          Reverse: <span className="text-light">{IpData.reverse ?? ""}</span>
        </li>
        <li>
          Country: <span className="text-light">{IpData.country ?? ""}</span>
        </li>
        <li>
          City: <span className="text-light">{IpData.city ?? ""}</span>
        </li>
        <li>
          Org: <span className="text-light">{IpData.org ?? ""}</span>
        </li>
        <li>
          ISP: <span className="text-light">{IpData.isp ?? ""}</span>
        </li>
        <li>
          ASN: <span className="text-light">{IpData.as ?? ""}</span>
        </li>
      </>
    );
  }, []);

  if (error) {
    return (
      <div className="result-container">
        <div id="result-box">
          <ul className="no-styling">
            <li className="text-light">{error}</li>
          </ul>
        </div>
      </div>
    );
  }

  if (tabType === "dns" && data) {
    return (
      <div className={`result-container ${trollDomains.includes(domain.toLowerCase()) ? "troll" : ""}`}>
        <div id="result-box">
          <ul className="no-styling">{renderDomainData(data, domainType)}</ul>
        </div>
      </div>
    );
  }

  if (tabType === "ip" && data) {
    return (
      <div className="result-container">
        <div id="result-box">
          <ul className="no-styling">{renderIpData(data)}</ul>
        </div>
      </div>
    );
  }

  if (tabType === "whois" && data) {
    return (
      <div className="result-container">
        <div id="result-box">
          <ul className="no-styling">{renderWhoisData(data)}</ul>
        </div>
      </div>
    );
  }

  return null;
};

export default ResultBox;
