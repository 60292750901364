import React, { useContext } from "react";
import { FormContext } from "../../Store/form-context";

import { Link } from "react-router-dom";

const TabButton = ({ onClickHandler, tabType, value }) => {
  const { state } = useContext(FormContext);

  const getTabStyle = (tabType, value) => {
    if (tabType === "domain") {
      if (state.tabType === value) return "tab active";
      return "tab";
    }

    if (tabType === "resolve") {
      if (state.dns.dnsType === value) return "tab active";
      return "tab";
    }
  };

  const onKeyDownHandler = (event, value) => {
    if (event.key === "Enter") {
      onClickHandler(value);
    }
  };

  if (tabType === "domain") {
    return (
      <Link to={`/${value}`}>
        <li>
          <span className={getTabStyle(tabType, value)}>{value.toUpperCase()}</span>
        </li>
      </Link>
    );
  }

  return (
    <li onClick={() => onClickHandler(value)} onKeyDown={(event) => onKeyDownHandler(event, value)}>
      <span tabIndex={0} className={getTabStyle(tabType, value)}>
        {value}
      </span>
    </li>
  );
};

export default TabButton;
