import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./SearchBox.css";

const getLabelText = (tabType) => {
  if (tabType === "dns") return "URL";
  if (tabType === "ip") return "IP";
  if (tabType === "whois") return "WHOIS";
  return "";
};

const SearchBox = ({ inputName, value, inputChangeHandler, placeholderValue, inputRef, isLoading, isValid }) => {
  React.useEffect(() => {
    inputRef.current.focus();
  }, [inputRef]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="search-box-top">
      <div className={isLoading ? "loader" : ""}></div>
      <form onSubmit={handleSubmit}>
        <div className="textfield-container">
          <label>
            <span className="label">{getLabelText(inputName)}</span>
            <input
              type="text"
              name={inputName}
              value={value}
              onChange={inputChangeHandler}
              placeholder={placeholderValue}
              ref={inputRef}
            />
          </label>
        </div>
        <div className="search-right-side">
          {value.length >= 1 ? (
            <span className={`status ${isValid ? "success" : "error"}`}>
              <FontAwesomeIcon icon={isValid ? faCheckCircle : faTimes} />
            </span>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default SearchBox;
