import React from "react";
import { fetchData } from "../utility/fetcher";

// cache will contain { ip: 'domain.com', data: [] }
let ipCache = {};

const initState = {
  loading: false,
  data: null,
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loading: true, data: null };
    case "FETCHED":
      return { ...state, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initState, error: action.payload };
    default:
      return state;
  }
};

const useIpFetch = (inputIsValid, ipInput) => {
  const [state, dispatch] = React.useReducer(reducer, initState);
  const URL = "/api/ip-lookup";

  React.useEffect(() => {
    if (!inputIsValid) return;

    // if ipInput is the same as in ipCache object -> set the state to the cached result
    if (ipCache.ip === ipInput) return dispatch({ type: "FETCHED", payload: ipCache.data });

    const fetchIpData = async () => {
      const body = {
        ip: ipInput.trim(),
      };
      try {
        const result = await fetchData(URL, body);
        dispatch({ type: "FETCHED", payload: result.data });
        // set the ipCache object to the newly fetched data
        ipCache = {
          ip: ipInput,
          data: result.data,
        };
      } catch (err) {
        console.log(err);
        dispatch({ type: "FETCH_ERROR", payload: err });
      }
    };

    dispatch({ type: "FETCHING" });
    const timer = setTimeout(() => {
      fetchIpData();
    }, 800);

    return () => {
      clearTimeout(timer);
    };
  }, [ipInput, inputIsValid]);

  return { ipData: state };
};

export default useIpFetch;
