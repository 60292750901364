import React from "react";
import { Helmet } from "react-helmet";

import { useLocation, useSearchParams } from "react-router-dom";

import { FormContext } from "../Store/form-context";
import useWhoisFetch from "../hooks/useWhoisFetch";

import HeroWrapper from "../components/HeroWrapper/HeroWrapper";
import TabWrapper from "../components/TabWrapper/TabWrapper";
import SearchBox from "../components/SearchBox/SearchBox";
import ResultBox from "../components/ResultBox/ResultBox";

const WhoisLookup = () => {
  const location = useLocation();
  const [_searchParams, setSearchParams] = useSearchParams();
  const { state, dispatch } = React.useContext(FormContext);
  const inputRef = React.createRef();
  const { whoisData } = useWhoisFetch(state.whois.isValid, state.whois.inputValue.toLowerCase());

  React.useEffect(() => {
    const urlPath = location.pathname.replace("/", "");

    dispatch({ type: "CHANGE_TAB_TYPE", payload: { tabType: urlPath } });
  }, [location.pathname, dispatch]);

  React.useEffect(() => {
    const domainNameParam = new URLSearchParams(location.search).get("domain");

    if (domainNameParam) {
      dispatch({ type: "ADD_WHOIS", payload: { inputValue: domainNameParam.toLowerCase() } });
      dispatch({ type: "ADD_DNS", payload: { inputValue: domainNameParam.toLowerCase() } });
    }
  }, [location.search, dispatch]);

  React.useEffect(() => {
    if (whoisData.data) setSearchParams({ ["domain"]: state.whois.inputValue });
  }, [whoisData]);

  const inputChangeHandler = (e) => {
    dispatch({ type: "ADD_WHOIS", payload: { inputValue: e.target.value } });
    dispatch({ type: "ADD_DNS", payload: { inputValue: e.target.value } });
  };

  return (
    <>
      <Helmet>
        <title>Domaintools - WHOIS</title>
        <meta name="description" content="Do a whois search for a specific domain" />
        <link rel="canonical" href="https://domaintools.nu/whois" />
      </Helmet>
      <HeroWrapper text="WHOIS" />
      <TabWrapper />

      <div className="search-container">
        <div className="search-box">
          <SearchBox
            inputName="whois"
            value={state.whois.inputValue}
            inputChangeHandler={inputChangeHandler}
            placeholderValue="example.com"
            inputRef={inputRef}
            isLoading={whoisData.loading}
            isValid={state.whois.isValid}
          />
        </div>
      </div>

      <ResultBox tabType={state.tabType} data={whoisData.data} />
    </>
  );
};

export default WhoisLookup;
