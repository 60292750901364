import React, { useContext } from "react";
import { FormContext } from "../../Store/form-context";

import TabButton from "./TabButton";

const domainTypes = ["A", "AAAA", "ANY", "CAA", "CNAME", "MX", "NS", "PTR", "SOA", "SRV", "TXT"];

const DNSTabWrapper = () => {
  const { dispatch } = useContext(FormContext);

  const changeDNSTypeHandler = (type) => {
    dispatch({ type: "CHANGE_DNS_TYPE", payload: { dnsType: type } });
  };

  return (
    <div className="search-box-bottom">
      <ul className="no-styling post-tabs js-tabs">
        {domainTypes.map((type, index) => (
          <TabButton key={index} onClickHandler={changeDNSTypeHandler} tabType="resolve" value={type} />
        ))}
      </ul>
    </div>
  );
};

export default DNSTabWrapper;
