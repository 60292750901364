import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { useLocation } from "react-router-dom";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import { FormContext } from "../Store/form-context";

import TabWrapper from "../components/TabWrapper/TabWrapper";
import HeroWrapper from "../components/HeroWrapper/HeroWrapper";

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

const DomainLookup = () => {
  const { state, dispatch } = useContext(FormContext);
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState("preview");

  const toggleEditMode = () => {
    if (selectedTab === "write") setSelectedTab("preview");
    if (selectedTab === "preview") setSelectedTab("write");
  };

  const inputChangeHandler = (value) => {
    dispatch({ type: "ADD_INFO", payload: { inputValue: value } });
    localStorage.setItem("info", JSON.stringify(value));
  };

  useEffect(() => {
    const urlPath = location.pathname.replace("/", "");
    dispatch({ type: "CHANGE_TAB_TYPE", payload: { tabType: urlPath === "" ? "dns" : urlPath } });
    const infoFromLocalStorage = JSON.parse(localStorage.getItem("info"));
    if (infoFromLocalStorage) {
      dispatch({ type: "ADD_INFO", payload: { inputValue: infoFromLocalStorage } });
    }
  }, [location.pathname, dispatch]);

  return (
    <>
      <Helmet>
        <title>Domaintools - Your Info Page</title>
        <meta name="description" content="Save imfo for you to use later" />
        <link rel="canonical" href="https://domaintools.nu/info" />
      </Helmet>
      <HeroWrapper text="YOUR INFO" />
      <TabWrapper />

      <div className="search-container">
        <div className="search-box info">
          <ReactMde
            value={state.info.inputValue}
            onChange={inputChangeHandler}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            toolbarCommands={[["bold", "italic", "ordered-list", "unordered-list", "code", "link"]]}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
            classes={{ toolbar: selectedTab === "preview" ? "markdown-not-editing" : "" }}
          />
          <span className="edit-toggle" onClick={toggleEditMode}>
            <FontAwesomeIcon icon={faPencilAlt} />
          </span>
        </div>
      </div>
    </>
  );
};

export default DomainLookup;
