import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import BrightnessLogo from "./components/Logos/BrightnessLogo";
import DarknessLogo from "./components/Logos/DarknessLogo";

import DomainLookup from "./pages/DomainLookup";
import IPLookup from "./pages/IPLookup";
import WhoisLookup from "./pages/WhoisLookup";
import InfoPage from "./pages/InfoPage";

import FormProvider from "./Store/form-context";

import useTheme from "./hooks/useTheme";

function App() {
  const { darkMode, setTheme } = useTheme();

  return (
    <Router>
      <div className="App">
        <section id="main">
          <div
            style={{
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {!darkMode ? (
              <DarknessLogo onClickToggle={() => setTheme("dark")} />
            ) : (
              <BrightnessLogo onClickToggle={() => setTheme("light")} />
            )}
          </div>
          <div className="wrapper">
            <FormProvider>
              <Routes>
                <Route path="/ip" element={<IPLookup />} />

                <Route path="/whois" element={<WhoisLookup />} />

                <Route path="/" element={<DomainLookup />} />
                <Route path="/dns" element={<DomainLookup />} />

                <Route path="/info" element={<InfoPage />} />
              </Routes>
            </FormProvider>
          </div>
        </section>
      </div>
    </Router>
  );
}

export default App;
