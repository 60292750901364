/**
 * Fetch IPv4 Validation
 * @param {*} searchTerm string,
 * @param {*} body object,
 * @param {*} fetchFunc function,
 */
export const fetchDecider = async (tabType, body, fetchFunc) => {
  if (tabType === "dns") return await fetchFunc("/api/dns-lookup", { domain: body.value, domainType: body.domainType });

  if (tabType === "ip") {
    return await fetchFunc("/api/ip-lookup", { ip: body.value });
  }

  if (tabType === "whois") {
    return await fetchFunc("/api/whois-lookup", { domain: body.value });
  }
};

/**
 * Fetch data
 * @param {*} url string
 * @param {*} body object
 */
export const fetchData = async (url, body) => {
  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  });
  const result = await response.json();
  return result;
};
