import React from "react";
import { fetchData } from "../utility/fetcher";

// cache will contain { domainName: 'domain.com', data: [] }
let whoisCache = {};

const initState = {
  loading: false,
  data: null,
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loading: true, data: null };
    case "FETCHED":
      return { ...state, loading: false, data: action.payload };
    case "FETCH_ERROR":
      return { ...initState, error: action.payload };
    default:
      return state;
  }
};

const useWhoisFetch = (inputIsValid, whoisInput) => {
  const [state, dispatch] = React.useReducer(reducer, initState);
  const URL = "/api/whois-lookup";

  React.useEffect(() => {
    if (!inputIsValid) return;

    // if whoisInput is the same as in whoisCache object -> set the state to the cached result
    if (whoisCache.domainName === whoisInput) return dispatch({ type: "FETCHED", payload: whoisCache.data });

    const fetchWhoisData = async () => {
      let searchQuery = whoisInput.trim();

      // Remove "http://" or "https://" from search string
      if (searchQuery.indexOf("http://") >= 0 || searchQuery.indexOf("https://") >= 0) {
        searchQuery = searchQuery.replace("https://", "").replace("http://", "");
      }

      // remove any trailing slashes
      searchQuery = searchQuery.replace(/\/+$/, "");

      const body = {
        domain: searchQuery,
      };
      try {
        const result = await fetchData(URL, body);
        dispatch({ type: "FETCHED", payload: result.data });
        // set the cache object to the newly fetched data
        whoisCache = {
          domainName: whoisInput,
          data: result.data,
        };
      } catch (err) {
        console.log(err);
        dispatch({ type: "FETCH_ERROR", payload: err });
      }
    };

    dispatch({ type: "FETCHING" });
    const timer = setTimeout(() => {
      fetchWhoisData();
    }, 800);

    return () => {
      clearTimeout(timer);
    };
  }, [whoisInput, inputIsValid]);

  return { whoisData: state };
};

export default useWhoisFetch;
