import * as React from "react";

const LOCALSTORAGE_KEY = "__DOMAINTOOLS__THEME";
const isDarkmode = (theme) => theme === "dark";

export default function useTheme() {
  const [theme, setTheme] = React.useState();

  React.useEffect(() => {
    if (window.localStorage.getItem(LOCALSTORAGE_KEY) === "dark") {
      return setTheme("dark");
    }

    return setTheme("light");
  }, []);

  React.useEffect(() => {
    if (!theme) return;

    window.localStorage.setItem(LOCALSTORAGE_KEY, theme);

    if (isDarkmode(theme)) {
      document.body.classList.add("darkmode");

      return;
    }

    document.body.classList.remove("darkmode");
  }, [theme]);

  return { darkMode: theme === "dark", setTheme };
}
