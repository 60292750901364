import React from "react";
import { Helmet } from "react-helmet";

import { useLocation, useSearchParams } from "react-router-dom";

import { FormContext } from "../Store/form-context";
import useIpFetch from "../hooks/useIpFetch";

import HeroWrapper from "../components/HeroWrapper/HeroWrapper";
import TabWrapper from "../components/TabWrapper/TabWrapper";
import SearchBox from "../components/SearchBox/SearchBox";
import ResultBox from "../components/ResultBox/ResultBox";

const IPLookup = () => {
  const location = useLocation();
  const [_searchParams, setSearchParams] = useSearchParams();
  const { state, dispatch } = React.useContext(FormContext);
  const inputRef = React.createRef();
  const { ipData } = useIpFetch(state.ip.isValid, state.ip.inputValue);

  React.useEffect(() => {
    const urlPath = location.pathname.replace("/", "");

    dispatch({ type: "CHANGE_TAB_TYPE", payload: { tabType: urlPath } });
  }, [location.pathname, dispatch]);

  React.useEffect(() => {
    const ipParam = new URLSearchParams(location.search).get("ip");

    if (ipParam) {
      dispatch({ type: "ADD_IP", payload: { inputValue: ipParam } });
    }
  }, [location.search, dispatch]);

  React.useEffect(() => {
    if (ipData.data) setSearchParams({ ["ip"]: state.ip.inputValue });
  }, [ipData]);

  const inputChangeHandler = (e) => {
    dispatch({ type: "ADD_IP", payload: { inputValue: e.target.value } });
  };

  return (
    <>
      <Helmet>
        <title>Domaintools - IP LOOKUP</title>
        <meta name="description" content="Lookup information for a specific IP" />
        <link rel="canonical" href="https://domaintools.nu/ip" />
      </Helmet>
      <HeroWrapper text="IP LOOKUP" />
      <TabWrapper />

      <div className="search-container">
        <div className="search-box">
          <SearchBox
            inputName="ip"
            value={state.ip.inputValue}
            inputChangeHandler={inputChangeHandler}
            placeholderValue="1.22.33.444"
            inputRef={inputRef}
            isLoading={ipData.loading}
            isValid={state.ip.isValid}
          />
        </div>
      </div>

      <ResultBox tabType={state.tabType} data={ipData.data} />
    </>
  );
};

export default IPLookup;
