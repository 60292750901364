import React from "react";

// import './Logos.css';

const BrightnessLogo = ({ onClickToggle }) => {
  return (
    <div
      style={{ padding: ".6rem", width: "max-content", cursor: "pointer" }}
      className="logo-wrapper light"
      onClick={onClickToggle}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="light icon icon-tabler icon-tabler-brightness-up"
        width="30"
        height="30"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <circle cx="12" cy="12" r="3" />
        <line x1="12" y1="5" x2="12" y2="3" />
        <line x1="17" y1="7" x2="18.4" y2="5.6" />
        <line x1="19" y1="12" x2="21" y2="12" />
        <line x1="17" y1="17" x2="18.4" y2="18.4" />
        <line x1="12" y1="19" x2="12" y2="21" />
        <line x1="7" y1="17" x2="5.6" y2="18.4" />
        <line x1="6" y1="12" x2="4" y2="12" />
        <line x1="7" y1="7" x2="5.6" y2="5.6" />
      </svg>
    </div>
  );
};

export default BrightnessLogo;
